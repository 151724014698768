<template>
  <div class="students-management">
    <div class="students-header clearfix">
      <div class="header-left fl">
        <el-select
          v-model="StudentsClass.grade"
          @change="getClass"
          placeholder="选择年级"
        >
          <el-option
            v-for="(item, index) in gradeoptions"
            :key="index"
            :label="item.id"
            :value="item.text"
          ></el-option>
        </el-select>
        <el-select v-model="StudentsClass.classes" placeholder="选择班级">
          <el-option
            v-for="(item, index) in classesOptions"
            :key="index"
            :label="item.id"
            :value="item.text"
          ></el-option>
        </el-select>
        <i class="el-icon-search search" @click="classChange"></i>
      </div>
      <div class="header-right fr">
        <el-button @click="addstudents">添加学生</el-button>
        <el-upload
          ref="upload"
          class="upload-demo"
          accept=".xlsx"
          :on-success="upSuccess"
          :on-error="uperror"
          :action="
            'https://api.joyousoa.com/school-report/student/upload?grade=' +
            encodeURIComponent(this.classForm.grade) +
            '&classes=' +
            encodeURIComponent(this.classForm.classes)
          "
          :headers="uploadHeaders"
          :on-change="uploadChange"
          multiple
          :file-list="fileList"
          :auto-upload="false"
          :on-exceed="exceed"
        >
          <el-button>批量导入</el-button>
        </el-upload>
        <span class="downloadTemplate">
          <a href="./students.xlsx" download="学生信息模板.xlsx">下载模板</a>
        </span>
      </div>
    </div>
    <div class="students-bot">
      <el-table
        :data="data"
        border
        :max-height="500"
        @selection-change="handleSelectionChange"
        :row-style="{ height: '50px', 'font-size': '16px', color: 'rgba(0, 0, 0,0.85)' }"
        style="width: 100%"
        :header-cell-style="{
          'font-size': '18px',
          'font-weight': '700',
          color: 'rgba(0, 0, 0,0.85)',
        }"
      >
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column
          type="index"
          width="60px"
          align="center"
          label="编号"
        ></el-table-column>
        <el-table-column
          prop="grade"
          label="年级"
          max-width="50px"
          align="center"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="classes"
          label="班级"
          max-width="50px"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="studentName"
          label="学生姓名"
          min-width="100px"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sno"
          label="学号"
          max-width="100px"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="gender"
          label="性别"
          max-width="50px"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="parent1"
          label="家长1"
          max-width="186px"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="parent1Mobile"
          label="电话1"
          min-width="125px"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="parent2"
          label="家长2"
          max-width="186px"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="parent2Mobile"
          label="电话2"
          min-width="125px"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="memo"
          label="备注"
          :show-overflow-tooltip="true"
          min-width="100px"
        ></el-table-column>
        <el-table-column align="center" label="操作" min-width="140">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <p class="BatchDelete" @click="BatchDelete">批量删除</p>
    <el-dialog
      :visible.sync="addDialogVisible"
      width="528px"
      height="818px"
      class="addStudentsDialog"
    >
      <p class="tableName" slot="title">
        <i class="el-icon-caret-right"></i>
        添加学生信息
      </p>
      <el-form ref="form" key="form" :model="form" label-width="54px" :rules="formRules">
        <el-form-item label="年级:" prop="grade">
          <el-select v-model="form.grade" placeholder="选择年级">
            <el-option
              v-for="(item, index) in options.Grade"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级:" prop="classes">
          <el-select v-model="form.classes" placeholder="选择班级">
            <el-option
              v-for="(item, index) in options.classes"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名:" prop="studentName">
          <el-input v-model="form.studentName"></el-input>
        </el-form-item>
        <el-form-item label="学号:" prop="sno">
          <el-input v-model="form.sno"></el-input>
        </el-form-item>
        <el-form-item label="性别:" class="radio" prop="gender">
          <el-radio-group v-model="form.gender">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="家长1:" prop="parent1">
          <el-input v-model="form.parent1"></el-input>
        </el-form-item>
        <el-form-item label="电话:">
          <el-input v-model="form.parent1Mobile"></el-input>
        </el-form-item>
        <el-form-item label="家长2:">
          <el-input v-model="form.parent2"></el-input>
        </el-form-item>
        <el-form-item label="电话:">
          <el-input v-model="form.parent2Mobile"></el-input>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" maxlength="50" v-model="form.memo"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.prevent="closeDialog">取消</el-button>
        <el-button class="submitUpload" @click.prevent="changeStudengts">提交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="classDialogVisible"
      width="528px"
      height="830px"
      class="classDialogVisible"
    >
      <p class="tableName" slot="title">
        <i class="el-icon-caret-right"></i>
        选择班级
      </p>
      <el-form
        ref="classForm"
        key="classForm"
        :model="classForm"
        label-width="54px"
        :rules="formRules"
      >
        <el-form-item label="年级:" prop="grade">
          <el-select v-model="classForm.grade" placeholder="选择年级">
            <el-option
              v-for="(item, index) in options.Grade"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级:" prop="classes">
          <el-select v-model="classForm.classes" placeholder="选择班级">
            <el-option
              v-for="(item, index) in options.classes"
              :key="index"
              :label="item.text"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.prevent="classDialogVisible = false">取消</el-button>
        <el-button class="submitUpload" @click.prevent="submitUpload">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import http from "@/http/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    let mobile = (rule, value, callback) => {
      let rules = /^1[3|4|5|6|7|8|9][0-9]\d{8}(,1[3|4|5|6|7|8|9][0-9]\d{8})*$/;
      if (!rules.test(value)) return callback(new Error("请输入正确的手机号"));
      callback();
    };
    return {
      StudentsClass: {
        grade: "",
        classes: "",
        size: 200,
        pageNumber: 0,
      },
      classForm: {
        grade: "",
        classes: "",
      },
      gradeoptions: [],
      classesOptions: [],
      data: [],
      addDialogVisible: false,
      classDialogVisible: false,
      fromType: true,
      //添加表单
      form: {
        studentName: "",
        grade: "",
        classes: "",
        id: "",
        gender: "",
        parent1: "",
        parent1Mobile: "",
        parent2: "",
        parent2Mobile: "",
        memo: "",
        sno: "",
      },
      formRules: {
        grade: [{ required: true, message: "请选择年级", trigger: "blur" }],
        classes: [{ required: true, message: "请选择班级", trigger: "blur" }],
        studentName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sno: [{ required: true, message: "请输入学号", trigger: "blur" }],
        gender: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur",
          },
        ],
      },
      addForm: {
        studentName: "",
        grade: "",
        classes: "",
        id: "",
        gender: "",
        parent1: "",
        parent1Mobile: "",
        parent2: "",
        parent2Mobile: "",
        memo: "",
        sno: "",
      },
      // 表格多选数组
      multipleSelection: [],
      // 上传学生列表请求头设置
      uploadHeaders: {
        Authorization: "Bearer" + " " + window.localStorage.getItem("Authorization"),
      },
      fromStatus: false,
      // 用户信息
      userInfo: {
        id: "",
        mobile: "",
        orgId: "",
        orgName: "",
        orgType: "",
        realName: "",
        username: "",
      },
      // 班年级选项信息
      options: {
        Grade: [],
        Course: [],
        classes: [],
        examType: [],
      },
      uploadFlag: true,
      fileList: [],
    };
  },
  created() {
    // 获取用户信息
    this.getUserInfo();
    // 获取年级班级
    this.getGrade();
  },
  mounted() {},
  methods: {
    handleEdit(index, row) {
      this.fromStatus = true;
      this.fromType = true;
      this.form = row;
      this.addDialogVisible = true;
    },
    // 获取用户信息
    async getUserInfo() {
      const data = await http.getUserInfo();
      if (data.status == 200) {
        this.userInfo = data.data;
        // 获取字典
        this.getOptions();
      } else {
        this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
    // 获取下拉选项
    async getOptions() {
      if (this.userInfo.orgType == "141203") {
        const Grade = await http.getElementaryGrade();
        if (Grade.status == 200) {
          this.options.Grade = Grade.data;
        } else {
          this.$message.error({
            message: Grade.msg,
            type: "error",
            duration: 3000,
          });
        }
        const Course = await http.getElementaryCourse();
        if (Course.status == 200) {
          this.options.Course = Course.data;
        } else {
          this.$message.error({
            message: Course.msg,
            type: "error",
            duration: 3000,
          });
        }
      } else {
        const Grade = await http.getMiddleGrade();
        if (Grade.status == 200) {
          this.options.Grade = Grade.data;
        } else {
          this.$message.error({
            message: Grade.msg,
            type: "error",
            duration: 3000,
          });
        }
        const Course = await http.getMiddleCourse();
        if (Course.status == 200) {
          this.options.Course = Course.data;
        } else {
          this.$message.error({
            message: Course.msg,
            type: "error",
            duration: 3000,
          });
        }
      }
      const classes = await http.getClasses();
      if (classes.status == 200) {
        this.options.classes = classes.data;
      } else {
        this.$message.error({
          message: classes.msg,
          type: "error",
          duration: 3000,
        });
      }
      const examType = await http.getExamType();
      if (examType.status == 200) {
        this.options.examType = examType.data;
      } else {
        this.$message.error({
          message: examType.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
    // 获取年级列表
    async getGrade() {
      const { data: data } = await http.getGrade();

      if (data.length) {
        this.gradeoptions = data;
        this.StudentsClass.grade = data[0].id;
        // 初始信息
        this.initial(data[0].id);
      } else {
        this.$message.error({
          message: "获取失败,请稍后再试!",
          type: "error",
          duration: 3000,
        });
      }
    },
    // 获取班级列表
    async getClass(grade) {
      this.StudentsClass.classes = "";
      // console.log(grade);
      try {
        const { data: data } = await http.getClass({
          grade: grade,
        });
        this.classesOptions = data;
      } catch (error) {
        this.$message.error({
          message: "获取班级失败,请稍后再试",
          type: "error",
          duration: 3000,
        });
      }
    },
    // 获取初始信息
    async initial(id) {
      const { data: data } = await http.getClass({
        grade: id,
      });
      if (data.length) {
        this.classesOptions = data;
        this.StudentsClass.classes = data[0].id;

        this.classChange();
      }
    },
    // 获取学生列表
    async getStudentList() {
      const { data: data } = await http.getStudentList(this.StudentsClass);
      if (data && Array.isArray(data)) {
        if (data.length == 0) {
          this.data = data;
          this.$message.error({
            message: "暂无学生信息",
            type: "error",
            duration: 3 * 1000,
          });
        } else {
          this.data = data;
        }
      } else {
        this.$message.error({
          message: data.msg || "获取失败",
          type: "error",
          duration: 3 * 1000,
        });
      }
    },
    // 切换班级病
    classChange() {
      // if (!this.StudentsClass.grade || !this.StudentsClass.classes)
      //   return this.$message.error({
      //     message: "请输入年级班级",
      //     type: "error",
      //     duration: 3 * 1000,
      //   });
      //获取学生列表
      this.getStudentList();
    },

    // 修改学生信息  添加学生
    async changeStudengts() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.fromType) {
            const { data: data } = await http.changeStudengts(this.form);
            if (data.code == 200) {
              this.$message.error({
                message: "修改成功",
                type: "error",
                duration: 3000,
              });
              //刷新学生列表
              this.classChange();
              this.$refs["form"].resetFields();
              this.addDialogVisible = false;
            } else {
              this.$message.error({
                message: data.msg,
                type: "error",
                duration: 3000,
              });
            }
          } else {
            const { data: data } = await http.addStudentList(this.form);

            if (data.code !== 200) {
              return this.$message.error({
                message: data.msg,
                type: "error",
                duration: 3000,
              });
            }
            this.$refs["form"].resetFields();
            this.addDialogVisible = false;
            //刷新学生列表
            this.classChange();
          }
        } else {
          this.$message.error({
            message: "请输入正确的信息",
            type: "error",
            duration: 3000,
          });
          return false;
        }
      });
    },
    // 添加学生信息
    addstudents() {
      this.fromStatus = false;
      this.form = { ...this.addForm };
      this.fromType = false;
      this.addDialogVisible = true;
    },

    // 超出提示
    exceed() {
      this.$message.error({
        message: "一次只能上传一张表格",
        type: "error",
        duration: 3000,
      });
    },
    //  上传成功处理
    async upSuccess(response, file, fileList) {
      this.$message.error({
        message: response.msg,
        type: "error",
        duration: 3000,
      });

      this.$refs.upload.clearFiles();
      this.fileList = [];
      //刷新学生列表
      this.classChange();
    },
    // 上传失败处理
    uperror(err, file, fileList) {
      this.$message.error({
        message: err.msg,
        type: "error",
        duration: 3000,
      });
      this.$refs.upload.clearFiles();
      this.fileList = [];
    },

    // 删除学生

    async handleDelete(index, row) {
      if (!row.id) return null;
      try {
        const { data: data } = await http.deleteStudent(row.id);
        this.$message.error({
          message: "删除成功",
          type: "error",
          duration: 3000,
        });
        //刷新学生列表
        this.classChange();
      } catch (error) {
        this.$message.error({
          message: "删除失败",
          type: "error",
          duration: 3000,
        });
      }
    },
    // 批量删除
    async BatchDelete() {
      let arr = [];
      this.multipleSelection.forEach((val) => {
        arr.push(val.id);
      });

      if (arr.length == 0)
        return this.$message.error({
          message: "请选择",
          type: "success",
          duration: 3000,
        });
      try {
        const { data: data } = await http.deleteStudent(arr.join(","));
        this.$message.error({
          message: "删除成功",
          type: "error",
          duration: 3000,
        });
        //刷新学生列表
        this.classChange();
      } catch (error) {
        this.$message.error({
          message: "删除失败",
          type: "error",
          duration: 3000,
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    submitUpload() {
      this.$refs["classForm"].validate((valid) => {
        if (valid) {
          this.StudentsClass.grade = this.classForm.grade;
          this.StudentsClass.classes = this.classForm.classes;
          this.$refs.upload.submit();
          this.classDialogVisible = false;
          this.classForm.grade = "";
          this.classForm.classes = "";
        } else {
          this.$message.error({
            message: "请选择班年级",
            type: "error",
            duration: 3000,
          });
        }
      });
    },
    uploadChange(file, fileList) {
      if (fileList.length) {
        this.classDialogVisible = true;
      }
    },

    closeDialog() {
      this.addDialogVisible = false;

      if (this.fromStatus) {
        //刷新学生列表
        this.classChange();
      }
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
::v-deep.students-management {
  width: 1280px;
  height: 612px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(131, 143, 153, 0.15);
  border-radius: 16px;
  margin: 70px auto;
  padding: 32px 40px;
  .students-header {
    height: 42px;
    line-height: 42px;
    margin-bottom: 10px;
    .header-left {
      height: 42px;
      line-height: 42px;
      .el-select {
        .el-input {
          input {
            width: 146px;
            height: 40px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #ff6161;
            margin-right: 10px;
            box-sizing: border-box;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #838f99;
          }
          i {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff6161;
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
          }
        }
      }
      .search {
        height: 38px;
        line-height: 38px;
        width: 60px;
        cursor: pointer;
        border-radius: 5px;
        &:hover {
          background-color: rgb(228, 128, 128);
        }
      }
    }
    .header-right {
      height: 42px;
      line-height: 42px;
      display: flex;
      .upload-demo {
        display: inline-block;
        .el-upload-list {
          display: none;
        }
      }
      .el-button {
        width: 108px;
        height: 42px;

        background: #eb6123;
        border-radius: 5px;
        margin-right: 12px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .downloadTemplate {
        margin-right: 22px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        a {
          color: #fd5600;
        }
      }
    }
  }
  .BatchDelete {
    text-align: left;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fd5600;
    margin-top: 16px;
    padding-left: 2px;
    cursor: pointer;
  }
  .addStudentsDialog {
    .el-dialog {
      margin-top: calc((100vh - 818px) / 2) !important;
      height: 818px;
    }
    .el-dialog__header {
      height: 46px;
    }
    .el-dialog__body {
      padding: 16px 20px;
      .el-form {
        padding: 0 16px;
        .el-form-item {
          &:last-of-type {
            margin-bottom: 0;
          }
          label {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            height: 44px;
            line-height: 44px;
            padding-right: 0;
            &::before {
              display: none;
            }
          }
          .el-input {
            input {
              height: 44px;
              width: 368px;
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #838f99;
            }
          }
          .el-textarea {
            width: 370px;
            max-height: 85px;
          }

          .el-form-item__error {
            margin-left: 20px;
          }
        }
        .radio {
          height: 24px;
          line-height: 24px;
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #717171;
          .el-form-item__content {
            height: 24px;
            line-height: 24px;
            .el-radio-group {
              margin-left: 10px;
              .el-radio {
                height: 24px;
                line-height: 24px;
              }
            }
          }
          .el-form-item__label {
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .classDialogVisible {
    .el-dialog {
      height: 330px;
      margin-top: calc((100vh - 300px) / 2) !important;
    }
    .el-dialog__header {
      height: 46px;
    }
    .el-dialog__body {
      padding: 16px 20px;
      .el-form {
        padding: 0 16px;
        .el-form-item {
          label {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            text-align: left;
            height: 44px;
            line-height: 44px;
            padding-right: 0;
            &::before {
              display: none;
            }
          }
          .el-input {
            input {
              height: 44px;
              width: 368px;
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #838f99;
            }
          }
          .el-form-item__error {
            margin-left: 20px;
          }
        }
        .radio {
          height: 24px;
          line-height: 24px;
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #717171;
          .el-form-item__content {
            height: 24px;
            line-height: 24px;
            .el-radio-group {
              margin-left: 10px;
              .el-radio {
                height: 24px;
                line-height: 24px;
              }
            }
          }
          .el-form-item__label {
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }
  .el-dialog__wrapper {
    .el-dialog {
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(131, 143, 153, 0.15);
      border-radius: 16px;
      .tableName {
        text-align: left;
        display: block;
        height: 44px;
        line-height: 44px;
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #eb6123;
        margin-bottom: 0px;
        border-bottom: 1px solid #eb6123;
        i {
          width: 20px;
          height: 20px;
          transform: scale3d(1.8, 1.2, 1);
          margin: 0 10px;
          text-shadow: 2px 0 3px #eb6123;
        }
      }
      .dialog-footer {
        text-align: center;
      }
    }
  }
}
.el-select-dropdown__item.selected {
  color: #eb6123;
}
</style>
<style lang="scss">
.classDialogVisible,
.addStudentsDialog {
  .el-button {
    width: 200px;
    height: 56px;
    border-radius: 27px;
    border: 1px solid #eb6123;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #eb6123;
    margin: 0 20px;
    &:active {
      background-color: unset;
      color: unset;
    }
    &:hover {
      border: 1px solid #eb6123;
      box-shadow: 0px 0px 5px 3px rgba($color: #eb6123, $alpha: 0.3);
      background-color: #fff;
    }
  }
}
</style>
